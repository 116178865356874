import { useCallback } from "react";
import { useEstateAppApiContext } from "src/contexts/estateAppApi-context";

import { FirstDegreePeopleRole, UserDataObj } from "src/types/snugtotal";

export enum GenericPath {
  // V3
  GENERIC_ENTITY_LIST_CREATE_V3 = "/v3/{ud_id}/{entity}/",
  GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/",

  GENERIC_DOCUMENT_CREATE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/",
  GENERIC_DOCUMENT_DELETE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{document_id}/",
  GENERIC_DOCUMENT_DOWNLOAD_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{document_id}/download",
  GENERIC_DOCUMENT_DOWNLOAD_ALL_V3 = "/v3/{ud_id}/{entity}/{entity_id}/download-all",

  SUBMIT_ESTATE_DOCUMENT_V3 = "/v3/{ud_id}/{entity}/{entity_id}/submit",
  DUPLICATE_ESTATE_DOCUMENT_V3 = "/v3/{ud_id}/{entity}/{entity_id}/duplicate",

  LEGACY_DOCUMENTS_CREATE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{legacy_documents_subcategory}/",
  LEGACY_DOCUMENTS_DELETE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{legacy_documents_subcategory}/{document_id}/",
  LEGACY_DOCUMENTS_DOWNLOAD_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{legacy_documents_subcategory}/{document_id}/download/",

  // ESTATE DOC URLS
  ESTATE_DOCUMENT_ITEM_LIST_CREATE_V3 = "/v3/{ud_id}/estate-document/{entity_id}/{subcategory}/",
  ESTATE_DOCUMENT_ITEM_RETRIEVE_UPDATE_DELETE_V3 = "/v3/{ud_id}/estate-document/{entity_id}/{subcategory}/{subcategory_id}/",

  ESTATE_DOCUMENT_ITEM_LIST_CREATE_SUBCAT2_V3 = "/v3/{ud_id}/estate-document/{entity_id}/{subcategory}/{subcategory_id}/{subcategory_2}/",
  ESTATE_DOCUMENT_ITEM_RETRIEVE_UPDATE_DELETE_SUBCAT2_V3 = "/v3/{ud_id}/estate-document/{entity_id}/{subcategory}/{subcategory_id}/{subcategory_2}/{subcategory_id_2}/",

  // Accpet Reject Invites
  INVITE = "/v3/{ud_id}/user-data/{entity_id}/invite",
  SHARE = "/v3/{ud_id}/user-data/{entity_id}/share",

  //USER DATA URLS
  USER_DATA_V3 = "/v3/user-data/",
  
  // Pro Urls
  ADD_SPOUSE_PLAN_FOR_PROFESSIONAL = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}/{subcategory_id}/add-spouse-plan",
}

type V3UrlParams = {
  ud_id: string;
  entity: string;
  subcategory?: string;
  subcategory_2?: string;
  legacy_documents_subcategory?: string;
  entity_id?: string;
  document_id?: string;
  subcategory_id?: string;
  subcategory_id_2?: string;
};

const replacePathPlaceholders = (pathTemplate: string, params: V3UrlParams) => {
  return pathTemplate
    .replace("{ud_id}", params.ud_id)
    .replace("{entity}", params.entity)
    .replace("{entity_id}", params.entity_id || "")
    .replace("{document_id}", params.document_id || "")
    .replace("{subcategory_id}", params.subcategory_id || "")
    .replace("{subcategory}", params.subcategory || "")
    .replace(
      "{legacy_documents_subcategory}",
      params.legacy_documents_subcategory || ""
    )
    .replace("{subcategory_2}", params.subcategory_2 || "")
    .replace("{subcategory_id_2}", params.subcategory_id_2 || "");
};

export const useV3GenericGetData = <T>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const genericGetData = useCallback(
    async (
      params: V3UrlParams,
      queryParams: Object = {},
      signal?: AbortSignal
    ): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);
      const response = await estateAppApiInstance.get(path, {
        params: queryParams,
        signal, // Pass the AbortSignal to the API request
      });

      return response.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return genericGetData;
};

export const useV3Update = <T>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const update = useCallback(
    async (
      params: V3UrlParams,
      data: any,
      isPartialUpdate: boolean = false
    ): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);
      let response;
      if (isPartialUpdate) {
        // Use PATCH for partial updates and PUT for full updates, depending on your API design
        response = await estateAppApiInstance.patch(path, data);
      } else {
        response = await estateAppApiInstance.put(path, data);
      }
      return response.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return update;
};

export const useV3Create = <T>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const create = useCallback(
    async (params: V3UrlParams, data: any, queryParams?: any): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);
      const response = await estateAppApiInstance.post(path, data, {
        params: queryParams,
      });
      return response.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return create;
};

export const useV3DeleteById = (genericPath: string): any => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteById = useCallback(
    async (params: V3UrlParams): Promise<void> => {
      const path = replacePathPlaceholders(
        replacePathPlaceholders(genericPath, params),
        params
      );
      const res = await estateAppApiInstance.delete(path);
      return res.data;
    },
    [estateAppApiInstance, genericPath]
  );
  return deleteById;
};

export const useV3DeleteByIdHiearchyRole = (genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteById = useCallback(
    async (params: V3UrlParams): Promise<FirstDegreePeopleRole[]> => {
      const path = replacePathPlaceholders(
        replacePathPlaceholders(genericPath, params),
        params
      );
      const res = await estateAppApiInstance.delete(path);
      return res.data.data as FirstDegreePeopleRole[];
    },
    [estateAppApiInstance, genericPath]
  );

  return deleteById;
};

/// START V3 DOCUMENTS

export const useV3UploadDocument = <T>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const uploadDocument = useCallback(
    async (params: V3UrlParams, file: File): Promise<T> => {
      const formData = new FormData();
      formData.append("file", file);
      const path = replacePathPlaceholders(genericPath, params);
      const res = await estateAppApiInstance.post(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return uploadDocument;
};

export const useV3DocumentDelete = (genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteDocument = useCallback(
    async (params: V3UrlParams): Promise<void> => {
      const path = replacePathPlaceholders(genericPath, params);
      await estateAppApiInstance.delete(path);
    },
    [estateAppApiInstance, genericPath]
  );

  return deleteDocument;
};

export const useV3DocumentDownload = (genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const downloadDocument = useCallback(
    async (params: V3UrlParams): Promise<void> => {
      const path = replacePathPlaceholders(genericPath, params);
      const res = await estateAppApiInstance.get(path);
      return res.data.data;
    },
    [estateAppApiInstance, genericPath]
  );
  return downloadDocument;
};

export const useV3DocumentDownloadAll = (genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const downloadDocumentAll = useCallback(
    async (
      params: V3UrlParams,
      document_name: string = "documents.zip"
    ): Promise<void> => {
      const path = replacePathPlaceholders(genericPath, params);
      const res = await estateAppApiInstance.get(path, {
        responseType: "blob", // Set the response type to blob
      });
      // Create a blob URL for the zip file
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", document_name);
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by removing the link and revoking the URL object
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    [estateAppApiInstance, genericPath]
  );
  return downloadDocumentAll;
};

// END V3 DOCUMENTS

//// BELOW IS IN THE PROCESS OF BEING DEPRECATED AND REPLACED WITH V3 GENERIC FUNCTIONS
interface HasId {
  id?: string | number | null;
}

/// V2 GENERIC FUNCTIONS - WE SHOULD BE USING THESE FOR ALL NEW ENDPOINTS
export const useAddOrUpdateData = <T extends HasId>(path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const addOrUpdateData = useCallback(
    async (data: T): Promise<T> => {
      if (data) {
        if (data["id"] && data["id"] !== "") {
          const response = await estateAppApiInstance.put(path, data);
          return response.data.data as T;
        } else {
          const response = await estateAppApiInstance.post(path, data);
          return response.data.data as T;
        }
      }
      return Promise.reject();
    },
    [estateAppApiInstance, path]
  );

  return addOrUpdateData;
};

export const useAddOrUpdateUserData = (path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const addOrUpdateData = useCallback(
    async (data: UserDataObj): Promise<UserDataObj> => {
      if (data) {
        if (data.ud_id && data.ud_id !== "") {
          const response = await estateAppApiInstance.put(path, data);
          return response.data.data as UserDataObj;
        } else {
          const response = await estateAppApiInstance.post(path, data);
          return response.data.data as UserDataObj;
        }
      }
      return Promise.reject();
    },
    [estateAppApiInstance, path]
  );

  return addOrUpdateData;
};

// export const useRunSummary = <T extends HasId>(
//   path: string,
//   readOnlyId?: number | null
// ) => {
//   const { estateAppApiInstance } = useEstateAppApiContext();

//   const addOrUpdateData = useCallback(
//     async (data: T): Promise<T> => {
//       if (data) {
//         const response = await estateAppApiInstance.post(
//           readOnlyId ? path.replace("v2", `v2/ro/${readOnlyId}`) : path,
//           data
//         );
//         return response.data.data as T;
//       }
//       return Promise.reject();
//     },
//     [estateAppApiInstance, path, readOnlyId]
//   );

//   return addOrUpdateData;
// };

export const useGenericGetData = <T>(
  path: string,
  readOnlyId?: number | null
) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const genericGetData = useCallback(
    async (params?: any): Promise<T> => {
      let readOnlyIdFunction = readOnlyId;
      // if params contains readOnly id then use that. Hacky workaround which will go away in v3
      if (params && params.readOnlyId) {
        readOnlyIdFunction = params.readOnlyId;
      }

      const response = await estateAppApiInstance.get(
        readOnlyIdFunction
          ? path.replace("v2", `v2/ro/${readOnlyIdFunction}`)
          : path,
        {
          params: params,
        }
      );
      return response.data.data as T;
    },
    [estateAppApiInstance, path, readOnlyId]
  );

  return genericGetData;
};

export const useDeleteById = <T>(path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteById = useCallback(
    async (id: string): Promise<T> => {
      if (id) {
        const response = await estateAppApiInstance.delete(path, {
          params: { id: id },
        });
        return response.data.data as T;
      }
      return Promise.reject();
    },
    [estateAppApiInstance, path]
  );

  return deleteById;
};

export const useDeleteWithPathParamId = <T>(path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteWithPathParamId = useCallback(
    async (id: string): Promise<T> => {
      if (id) {
        const response = await estateAppApiInstance.delete(
          path.replace("{id}", id)
        );
        return response.data.data as T;
      }
      return Promise.reject();
    },
    [estateAppApiInstance, path]
  );

  return deleteWithPathParamId;
};

export const useDeleteWithParams = <T>(path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteWithParams = useCallback(
    async (params: any): Promise<T> => {
      if (params) {
        const response = await estateAppApiInstance.delete(path, {
          params: params,
        });
        return response.data.data as T;
      }
      return Promise.reject();
    },
    [estateAppApiInstance, path]
  );

  return deleteWithParams;
};

// export const useUploadGeneralDocument = () => {
//   const { estateAppApiInstance } = useEstateAppApiContext();

//   const uploadGeneralDocument = useCallback(
//     async (
//       file: File,
//       object_id: string | null,
//       object_type:
//         | "debt"
//         | "digital_asset"
//         | "history"
//         | "people"
//         | "tax"
//         | "asset"
//         | "estate_document"
//         | "other_legal"
//     ) => {
//       if (!file) return Promise.reject("No file provided");
//       if (!object_id || !object_type)
//         return Promise.reject("No object_id or object_type provided");

//       const formData = new FormData();
//       formData.append("file", file);
//       formData.append("object_id", object_id);
//       formData.append("object_type", object_type);
//       return await estateAppApiInstance.put(
//         GenericPath.DOCUMENT_GENERAL,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//     },
//     [estateAppApiInstance]
//   );

//   return uploadGeneralDocument;
// };

/// ADD V3 api's here
