import { useCallback, useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { useMounted } from "src/hooks/use-mounted";
import { slackHook } from "src/utils/snug-slack-hooks";
import type { File } from "src/components/file-dropzone";
import {
  EstateDocument,
  EstateDocumentTypes,
  MinimalEstateDocument,
} from "src/types/snugdocs";
import { downloadFileFromS3 } from "src/utils/snug";
import {
  GenericPath,
  useV3Create,
  useV3DeleteById,
  useV3DocumentDownload,
  useV3DocumentDownloadAll,
  useV3GenericGetData,
  useV3Update,
  useV3UploadDocument,
} from "src/api/snugtotal/estate_app";

type estateDocumentAction =
  | { type: "SET_ESTATE_DOCS"; payload: MinimalEstateDocument[] }
  | {
      type: "SET_DETAIL_ESTATE_DOC";
      payload: EstateDocument | null | undefined;
    }
  | { type: "SET_ESTATE_DOCS_COUNT"; payload: number }
  | { type: "SET_ESTATE_DOCS_INITIAL_LOADING"; payload: boolean }
  | { type: "SET_ESTATE_DOCS_SAVING"; payload: boolean }
  | { type: "SET_ESTATE_DOCS_FILES_DOWNLOADING"; payload: string[] }
  | { type: "SET_ESTATE_DOCS_FILES_TO_UPLOAD"; payload: File[] }
  | { type: "SET_CURRENT_EXISTING_TRUST_NAME"; payload: string | null };

interface estateDocumentStoreState {
  estateDocuments: MinimalEstateDocument[];
  estateDocumentDetail?: EstateDocument | null; //use this sstate element to edit, change, save.
  estateDocumentCount: number;
  estateDocumentInitialLoading: boolean;
  estateDocumentSaving: boolean;
  estateDocFilesDownloading: string[];
  estateDocFilesToUpload: File[];
}

const estateDocumentStoreReducer = (
  state: estateDocumentStoreState,
  action: estateDocumentAction
): estateDocumentStoreState => {
  switch (action.type) {
    case "SET_ESTATE_DOCS":
      return { ...state, estateDocuments: action.payload };
    case "SET_ESTATE_DOCS_COUNT":
      return { ...state, estateDocumentCount: action.payload };
    case "SET_DETAIL_ESTATE_DOC":
      return { ...state, estateDocumentDetail: action.payload };
    case "SET_ESTATE_DOCS_INITIAL_LOADING":
      return { ...state, estateDocumentInitialLoading: action.payload };
    case "SET_ESTATE_DOCS_SAVING":
      return { ...state, estateDocumentSaving: action.payload };
    case "SET_ESTATE_DOCS_FILES_DOWNLOADING":
      return { ...state, estateDocFilesDownloading: action.payload };
    case "SET_ESTATE_DOCS_FILES_TO_UPLOAD":
      return { ...state, estateDocFilesToUpload: action.payload };
    default:
      return state;
  }
};

export const useEstateDocumentStore = (
  ud_id: string | null,
  docId?: string,
  type?: string[]
) => {
  const isMounted = useMounted();

  const [state, dispatch] = useReducer(estateDocumentStoreReducer, {
    estateDocuments: [],
    estateDocumentDetail: undefined,
    estateDocumentCount: 0,
    estateDocumentInitialLoading: true,
    estateDocumentSaving: false,
    estateDocFilesDownloading: [],
    estateDocFilesToUpload: [],
  });

  const getEstateDocumentsList = useV3GenericGetData<MinimalEstateDocument[]>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );

  const getSingleEstateDocument = useV3GenericGetData<EstateDocument>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );

  const createEstateDocument = useV3Create<
    EstateDocument | MinimalEstateDocument
  >(GenericPath.GENERIC_ENTITY_LIST_CREATE_V3);

  const updateEstateDocument = useV3Update<
    EstateDocument | MinimalEstateDocument
  >(GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3);

  const updateMinimalEstateDocumentOnly = useV3Update<MinimalEstateDocument>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );

  const submitDocument = useV3Update<EstateDocument>(
    GenericPath.SUBMIT_ESTATE_DOCUMENT_V3
  );

  const duplicateDocument = useV3Create<EstateDocument>(
    GenericPath.DUPLICATE_ESTATE_DOCUMENT_V3
  );

  const downloadDocument = useV3DocumentDownload(
    GenericPath.GENERIC_DOCUMENT_DOWNLOAD_V3
  );

  const deleteDocumentById = useV3DeleteById(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );

  const uploadDocument = useV3UploadDocument<EstateDocument>(
    GenericPath.GENERIC_DOCUMENT_CREATE_V3
  );

  const downloadAll = useV3DocumentDownloadAll(
    GenericPath.GENERIC_DOCUMENT_DOWNLOAD_ALL_V3
  );

  const willsAndTrusts = state.estateDocuments.filter(
    (doc) => doc.type === "WILL" || doc.type === "TRUST"
  );
  const fpoas = state.estateDocuments.filter(
    (doc) => doc.type === "FINANCIAL_POWER_OF_ATTORNEY"
  );
  const hcds = state.estateDocuments.filter(
    (doc) => doc.type === "HEALTHCARE_DIRECTIVE"
  );

  // use storage function and single call estate document function above as templates for the the new functions below
  const handleAddOrEditEstateDocumentSingleCall = useCallback(
    async (
      estateDocument: EstateDocument,
      isPartialUpdate = false
    ): Promise<MinimalEstateDocument | null> => {
      if (!ud_id) {
        return Promise.reject("Missing ud_id");
      }

      try {
        const isUpdate = Boolean(estateDocument.id);
        let response;

        if (isUpdate) {
          // Update logic
          response = await updateEstateDocument(
            {
              ud_id: ud_id,
              entity: "estate-documents",
              entity_id: estateDocument.id || undefined,
            },
            estateDocument,
            isPartialUpdate
          );
        } else {
          // Create logic
          response = await createEstateDocument(
            { ud_id: ud_id, entity: "estate-documents" }, // Adjust according to your actual API parameter requirements
            estateDocument
          );
        }
        return response;
      } catch (error) {
        toast.error("Failed to save estate document");
        console.error(error);
        return null;
      }
    },
    [ud_id, createEstateDocument, updateEstateDocument]
  );

  const handleUploadFiles = useCallback(
    async (
      estateDocument: MinimalEstateDocument,
      files: File[]
    ): Promise<MinimalEstateDocument | null> => {
      const totalFiles = files.length;
      let successCount = 0;
      let failCount = 0;
      let response: Promise<MinimalEstateDocument | null> =
        Promise.resolve(estateDocument);

      if (!ud_id || !estateDocument.id) {
        return Promise.reject("Missing ud_id or estateDocument.id");
      }

      for (let index = 0; index < totalFiles; index++) {
        const currentFile = files[index];
        try {
          const res = await uploadDocument(
            {
              ud_id: ud_id,
              entity: "estate-documents",
              entity_id: estateDocument.id,
            },
            currentFile
          );
          if (res) {
            response = Promise.resolve(res);
            // the ressponse from uploadGeneralDocument is the updated EstateDocument with the updated documents array object in it. So we need to update the state of the estateDocument array with the updated EstateDocument
            const index = state.estateDocuments.findIndex(
              (p) => p.id === res.id
            );
            let updatedEstateDocument;
            if (index > -1) {
              updatedEstateDocument = [
                ...state.estateDocuments.slice(0, index),
                res,
                ...state.estateDocuments.slice(index + 1),
              ];
            } else {
              // this should never happen as the id will always be there, but just in case
              updatedEstateDocument = [res, ...state.estateDocuments];
              dispatch({
                type: "SET_ESTATE_DOCS_COUNT",
                payload: state.estateDocumentCount + 1,
              });
            }
            dispatch({
              type: "SET_ESTATE_DOCS",
              payload: updatedEstateDocument,
            });
          }
          successCount++;
          const successMessage = `${successCount} of ${totalFiles} files uploaded successfully`;
          // Call your toast function here, e.g:
          toast.success(successMessage);
        } catch (error) {
          failCount++;
          const errorMessage = `${failCount} of ${totalFiles} files failed to upload`;
          // Call your toast function here, e.g:
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      }
      return response;
    },
    [state.estateDocuments, state.estateDocumentCount, uploadDocument, ud_id]
  );

  const handleAddOrEditEstateDocument = useCallback(
    async (
      estateDocument: EstateDocument,
      isPartialUpdate = false
    ): Promise<MinimalEstateDocument | null> => {
      dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });
      if (state.estateDocFilesToUpload.length > 0) {
        try {
          const res = await handleAddOrEditEstateDocumentSingleCall(
            estateDocument,
            isPartialUpdate
          );
          if (res) {
            await handleUploadFiles(res, state.estateDocFilesToUpload);
            return res;
          }
          return null;
        } catch (error) {
          toast.error("Oh no! Something went wrong. Please try again");
          slackHook(`ERROR: Possible action required: ${error}`);
          console.error(error);
          throw error;
        } finally {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
          dispatch({
            type: "SET_ESTATE_DOCS_FILES_TO_UPLOAD",
            payload: [],
          });
        }
      } else {
        try {
          return handleAddOrEditEstateDocumentSingleCall(
            estateDocument,
            isPartialUpdate
          );
        } catch (error) {
          toast.error("Failed to add or edit estateDocument. Please try again");
          console.error(error);
          throw error;
        } finally {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
        }
      }
    },
    [
      handleAddOrEditEstateDocumentSingleCall,
      handleUploadFiles,
      state.estateDocFilesToUpload,
    ]
  );

  const handleEdiEstateDocumentPatchOnly = useCallback(
    async (estateDocument: Partial<MinimalEstateDocument>): Promise<void> => {
      if (!ud_id) {
        return Promise.reject("Missing ud_id");
      }
      try {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });
        // Update logic
        const response = await updateMinimalEstateDocumentOnly(
          {
            ud_id: ud_id,
            entity: "estate-documents",
            entity_id: estateDocument.id || undefined,
          },
          estateDocument,
          true
        );
        // update state of estateDocuments array with the updated estateDocument (minimal estate document)
        const index = state.estateDocuments.findIndex(
          (p) => p.id === response.id
        );
        let updatedEstateDocument;
        if (index > -1) {
          updatedEstateDocument = [
            ...state.estateDocuments.slice(0, index),
            response,
            ...state.estateDocuments.slice(index + 1),
          ];
        } else {
          // this should never happen as the id will always be there, but just in case
          updatedEstateDocument = [response, ...state.estateDocuments];
        }
        dispatch({
          type: "SET_ESTATE_DOCS",
          payload: updatedEstateDocument,
        });
        if (state.estateDocFilesToUpload.length > 0) {
          await handleUploadFiles(response, state.estateDocFilesToUpload);
        }
      } catch (error) {
        toast.error("Failed to save estate document");
        console.error(error);
        throw error;
      } finally {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
      }
    },
    [
      ud_id,
      updateMinimalEstateDocumentOnly,
      handleUploadFiles,
      state.estateDocuments,
      state.estateDocFilesToUpload,
    ]
  );

  const handleGetEstateDocuments = useCallback(
    async (ud_id: string, queryParams?: { type?: string; status?: string }) => {
      try {
        let response;

        const paramsV3 = {
          ud_id: ud_id, //bleh. REFACTOR IN v3
          entity: "estate-documents",
        };

        response = await getEstateDocumentsList(paramsV3, queryParams);

        if (isMounted()) {
          dispatch({
            type: "SET_ESTATE_DOCS",
            payload: response as MinimalEstateDocument[],
          });
          dispatch({
            type: "SET_ESTATE_DOCS_COUNT",
            payload: (response as MinimalEstateDocument[]).length,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({
            type: "SET_ESTATE_DOCS_INITIAL_LOADING",
            payload: false,
          });
        }
      }
    },
    [isMounted, getEstateDocumentsList]
  );

  // rewrite download function to follow storage download pattern
  const handleDownloadEstateDocumentDocument = useCallback(
    async (
      estateDocument_id: string,
      document_id: string | null
    ): Promise<void> => {
      if (!document_id || !estateDocument_id || !ud_id) {
        return Promise.reject("No id provided");
      }
      toast.success("Downloading file...");

      const params = {
        ud_id: ud_id,
        document_id: document_id,
        entity_id: estateDocument_id,
        entity: "estate-documents",
      };

      dispatch({
        type: "SET_ESTATE_DOCS_FILES_DOWNLOADING",
        payload: [...state.estateDocFilesDownloading, document_id],
      });

      try {
        await downloadDocument(params).then((res: any) => {
          if (res) {
            downloadFileFromS3(
              res,
              state.estateDocuments
                ?.find((p) => p.id === estateDocument_id)
                ?.documents?.find((d) => d.document_id === document_id)
                ?.document_name || "Snug_Download.error"
            );
          } else {
            slackHook(
              `Document Error: Failed get document url from location ${GenericPath.GENERIC_DOCUMENT_DOWNLOAD_V3} with message "No response"`
            );
          }
        });
      } catch (err) {
        toast.error("Failed to download file");
        slackHook(
          `Document Error: Failed get document url from location ${GenericPath.GENERIC_DOCUMENT_DOWNLOAD_V3} with message "${err}"`
        );
      } finally {
        dispatch({
          type: "SET_ESTATE_DOCS_FILES_DOWNLOADING",
          payload: state.estateDocFilesDownloading.filter(
            (d) => d !== document_id
          ),
        });
      }
    },
    [
      downloadDocument,
      state.estateDocuments,
      ud_id,
      state.estateDocFilesDownloading,
    ]
  );

  const handleDeleteEstateDocumentDocument = useCallback(
    async (
      estateDocument_id: string,
      document_id: string | null
    ): Promise<void> => {
      if (!document_id || !estateDocument_id || !ud_id) {
        return Promise.reject("No id provided");
      }

      try {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });

        await deleteDocumentById({
          ud_id: ud_id,
          entity: "estate-documents",
          entity_id: estateDocument_id,
          document_id: document_id,
        });
        if (isMounted()) {
          // remove the document object formm the document array in the EstateDocument object
          const updatedEstateDocument = state.estateDocuments.map((p) => {
            if (p.id === estateDocument_id) {
              return {
                ...p,
                documents: p.documents
                  ? p.documents.filter((d) => d.document_id !== document_id)
                  : [],
              };
            }
            return p;
          });
          dispatch({
            type: "SET_ESTATE_DOCS",
            payload: updatedEstateDocument,
          });
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to delete document");
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
        }
      }
    },
    [isMounted, state.estateDocuments, deleteDocumentById, ud_id]
  );

  const handleEstateDocumentFilesDrop = useCallback(
    (newFiles: File[], isSignedCopy: boolean = false): void => {
      // if isSignedCopy is true, then loop through each file and create new file with "(Signed) - " appended to the front of the new file name if it is already not like that
      let newNewFiles: File[] = [];
      if (isSignedCopy) {
        newNewFiles = newFiles.map((file) => {
          if (!file.name.startsWith("(Signed) - ")) {
            return new File([file], `(Signed) - ${file.name}`, {
              type: file.type,
            });
          }
          return file;
        });
      } else {
        newNewFiles = newFiles;
      }

      dispatch({
        type: "SET_ESTATE_DOCS_FILES_TO_UPLOAD",
        payload: [...state.estateDocFilesToUpload, ...newNewFiles],
      });
    },
    [state.estateDocFilesToUpload]
  );

  const handleEstateDocumentFileRemove = useCallback(
    (file: File): void => {
      const updatedFiles = state.estateDocFilesToUpload.filter(
        (_file) => _file.path !== file.path
      );
      dispatch({
        type: "SET_ESTATE_DOCS_FILES_TO_UPLOAD",
        payload: updatedFiles,
      });
    },
    [state.estateDocFilesToUpload]
  );

  const handleEstateDocumentFilesRemoveAll = useCallback((): void => {
    dispatch({ type: "SET_ESTATE_DOCS_FILES_TO_UPLOAD", payload: [] });
  }, []);

  const handleEstateDocPartialUpdate = useCallback(
    async (
      detailEstateDocumentId: string | null,
      data: any
    ): Promise<EstateDocument> => {
      if (!detailEstateDocumentId || !ud_id) {
        return Promise.reject("No id provided");
      }

      const params = {
        ud_id: ud_id,
        entity: "estate-documents",
        entity_id: detailEstateDocumentId,
      };

      try {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });
        const res = await updateEstateDocument(
          params,
          {
            ...data,
            id: detailEstateDocumentId,
          },
          true
        );
        if (isMounted()) {
          dispatch({
            type: "SET_DETAIL_ESTATE_DOC",
            payload: res as EstateDocument,
          });
        }
        return res as EstateDocument;
      } catch (err) {
        console.error(err);
        toast.error(
          "There was an error saving some data! Please review your choices and try again."
        );
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
        }
      }
    },
    [isMounted, updateEstateDocument, ud_id]
  );

  //This is function similar to partial update above except that it does not set state from response. only used in one place atm, FPOA. Rethink this later.
  const handleEstateDocPartialUpdateFromDebounce = useCallback(
    async (id: string | null, data: any): Promise<EstateDocument> => {
      if (!id || !ud_id) {
        return Promise.reject("No id provided");
      }

      const params = {
        ud_id: ud_id,
        entity: "estate-documents",
        entity_id: id,
      };

      try {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });
        const res = await updateEstateDocument(
          params,
          { ...data, id: id },
          true
        );
        // No save of state here. This may be an issue because of the mismatch states in component and here.... maybe should keep all state in this component.
        return res as EstateDocument;
      } catch (err) {
        console.error(err);
        toast.error(
          "There was an error saving some data! Please review your choices and try again."
        );
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
        }
      }
    },
    [isMounted, updateEstateDocument, ud_id]
  );

  const submitEstateDocForCreation = useCallback(
    async (
      estateDocumentId: string | null,
      user_name: string | null,
      name: string | null,
      data: EstateDocumentTypes | null
    ): Promise<EstateDocument> => {
      if (!estateDocumentId || !user_name || !name || !ud_id) {
        return Promise.reject("Missing required data");
      }

      try {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });

        const params = {
          ud_id: ud_id,
          entity: "estate-documents",
          entity_id: estateDocumentId,
        };

        const res = await submitDocument(params, {
          id: estateDocumentId,
          user_name: user_name,
          name: name,
          data,
        } as EstateDocument);
        if (isMounted()) {
          dispatch({
            type: "SET_ESTATE_DOCS",
            payload: state.estateDocuments.map((p) => {
              if (p.id === estateDocumentId) {
                return res as MinimalEstateDocument;
              }
              return p;
            }),
          });
        }
        toast.success("Success! Your documents are now being prepared.");
        return res;
      } catch (err) {
        console.error(err);
        toast.error(
          "There was an error saving some data! Please review your choices and try again."
        );
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
        }
      }
    },
    [isMounted, state.estateDocuments, submitDocument, ud_id]
  );

  const duplicateEstateDocument = useCallback(
    async (estateDocumentId: string | null): Promise<EstateDocument> => {
      if (!estateDocumentId || !ud_id) {
        return Promise.reject("Missing required data");
      }

      try {
        dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: true });

        const params = {
          ud_id: ud_id,
          entity: "estate-documents",
          entity_id: estateDocumentId,
        };

        const res = await duplicateDocument(params, {} as EstateDocument);
        if (isMounted()) {
          dispatch({
            type: "SET_ESTATE_DOCS",
            payload: state.estateDocuments.map((p) => {
              if (p.id === estateDocumentId) {
                return res as MinimalEstateDocument;
              }
              return p;
            }),
          });
        }
        return res;
      } catch (err) {
        console.error(err);
        toast.error(
          "There was an error duplicating the document! Please try again."
        );
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_ESTATE_DOCS_SAVING", payload: false });
        }
      }
    },
    [isMounted, state.estateDocuments, duplicateDocument, ud_id]
  );

  const handleGetSingleEstateDocument = useCallback(
    async (ud_id: string, id: string | null): Promise<void> => {
      if (!id) {
        return Promise.reject("No id provided");
      }
      try {
        const params = {
          ud_id: ud_id, //bleh. REFACTOR IN v3
          entity: "estate-documents",
          entity_id: id,
        };

        const response = await getSingleEstateDocument(params);
        if (isMounted()) {
          dispatch({
            type: "SET_DETAIL_ESTATE_DOC",
            payload: response as EstateDocument,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({
            type: "SET_ESTATE_DOCS_INITIAL_LOADING",
            payload: false,
          });
        }
      }
    },
    [isMounted, getSingleEstateDocument]
  );

  const handleDownloadAllDocuments = useCallback(
    async (estateDocument: MinimalEstateDocument | null) => {
      if (!estateDocument?.id || !ud_id) {
        toast.error("No estate document ID provided");
        return;
      }

      try {
        // Notify the user that the download is starting
        toast.success("Downloading all documents...");
        const params = {
          ud_id: ud_id,
          entity: "estate-documents",
          entity_id: estateDocument.id,
        };
        // Make the request to your Django API
        await downloadAll(params, estateDocument.name || "documents.zip");
      } catch (error) {
        // Handle any errors during the request
        toast.error("Failed to download documents");
        console.error("Download error:", error);
      }
    },
    [downloadAll, ud_id]
  );

  useEffect(() => {
    if (docId && ud_id) {
      handleGetSingleEstateDocument(ud_id, docId);
    } else if (ud_id) {
      handleGetEstateDocuments(ud_id, {
        type: [
          "WILL",
          "TRUST",
          "HEALTHCARE_DIRECTIVE",
          "FINANCIAL_POWER_OF_ATTORNEY",
        ].join(","),
        status: [
          "APPROVED",
          "IN_PROGRESS",
          "PENDING",
          "REVIEWABLE",
          "ANALYSIS_PENDING",
          "ANALYSIS_COMPLETE",
          "ERROR",
          "SIGNED",
          "UNSET",
        ].join(","),
      });
    }

    return () => {
      dispatch({
        type: "SET_DETAIL_ESTATE_DOC",
        payload: undefined,
      });
    };
  }, [
    handleGetEstateDocuments,
    handleGetSingleEstateDocument,
    docId,
    ud_id,
    type,
  ]);

  useEffect(() => {
    if (state.estateDocuments.length > 0) {
      const trustDocuments = state.estateDocuments.filter(
        (doc) => doc.type === "TRUST"
      );
      if (trustDocuments.length > 0) {
        dispatch({
          type: "SET_CURRENT_EXISTING_TRUST_NAME",
          payload: trustDocuments[0].name,
        });
      } else {
        dispatch({
          type: "SET_CURRENT_EXISTING_TRUST_NAME",
          payload: null,
        });
      }
    }
  }, [state.estateDocuments]);

  return {
    ...state,
    willsAndTrusts,
    fpoas,
    hcds,
    handleGetEstateDocuments,
    handleAddOrEditEstateDocument,
    handleDownloadEstateDocumentDocument,
    handleDeleteEstateDocumentDocument,
    handleEstateDocumentFilesDrop,
    handleEstateDocumentFileRemove,
    handleEstateDocumentFilesRemoveAll,
    handleEstateDocPartialUpdate,
    handleEstateDocPartialUpdateFromDebounce,
    handleEdiEstateDocumentPatchOnly,
    submitEstateDocForCreation,
    handleDownloadAllDocuments,
    duplicateEstateDocument,
  };
};
