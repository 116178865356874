import { type FC } from "react";
import PropTypes from "prop-types";
import ChevronSelectorVerticalIcon from "@untitled-ui/icons-react/build/esm/ChevronSelectorVertical";
import type { SxProps } from "@mui/system/styleFunctionSx";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";

import { usePopover } from "src/hooks/use-popover";

import { TenantPopover } from "./tenant-popover";

import useUserDataProvider from "src/contexts/userData-context";
import { TargetPeopleRole } from "src/types/snugtotal";
import {
  authorizationRolesArray,
  peopleRoleRelationshipMap,
} from "src/utils/snug";
import { CircularProgress } from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

interface TenantSwitchProps {
  sx?: SxProps;
  onAddPlanClick: () => void;
  onVerifyUserClick: (id: string) => void;
}

export const TenantSwitch: FC<TenantSwitchProps> = (props) => {
  const popover = usePopover<HTMLButtonElement>();
  const {
    ownerUserData,
    targetAuthRolesLoading,
    targetAuthRoles,
    loadingViewingUserData,
    userData,
    proClientContext,
    handleSetAuthorizationContext,
    handleSetClientContext,
  } = useUserDataProvider();

  let displayList: TargetPeopleRole[] = [];
  if (proClientContext) {
    if (proClientContext?.role_target_user_data_ud_id === userData?.ud_id) {
      displayList = [
        {
          role: "SNUG_OWNER",
          id: "",
          user_data: userData,
        },
        ...targetAuthRoles.filter((pR) =>
          authorizationRolesArray.includes(pR.role || "")
        ),
      ];
    } else {
      displayList = [];
    }
  } else {
    displayList = [
      {
        role: "SNUG_OWNER",
        id: "",
        user_data: ownerUserData,
      },
      ...targetAuthRoles.filter((pR) =>
        authorizationRolesArray.includes(pR.role || "")
      ),
    ];
  }

  const handleSelect = async (authorization: TargetPeopleRole) => {
    await handleSetAuthorizationContext(authorization, null);
    popover.handleClose();
  };

  function stringAvatar(name: string) {
    return {
      children:
        name.split(" ").length > 1
          ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
          : name.split(" ")[0][0],
    };
  }

  const topDisplay = displayList.find(
    (t) => t.user_data?.ud_id === userData?.ud_id
  );

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        {...props}
        onClick={popover.handleOpen}
        sx={{
          backgroundColor: "white",
          p: 1.2,
          m: 1.5,
          mb: 3,
          borderRadius: 2,
          cursor: "pointer",
        }}
      >
        {targetAuthRolesLoading ? (
          <CircularProgress size={30} />
        ) : (
          <>
            {proClientContext ? ( //then is on client experience.
              <>
                <>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    invisible={!userData?.has_auth_user}
                  >
                    <Avatar
                      {...stringAvatar(userData?.full_name || "")}
                      sx={{ width: 30, height: 30 }}
                    />
                  </StyledBadge>
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflow: "hidden",
                      width: "100%",
                      lineHeight: 0.8,
                    }}
                  >
                    <Typography
                      color="inherit"
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      Client: {userData?.full_name || ""}
                    </Typography>
                  </Box>
                </>
              </>
            ) : (
              <>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  invisible={topDisplay?.role !== "SNUG_OWNER"}
                >
                  <Avatar
                    {...stringAvatar(topDisplay?.user_data?.full_name || "")}
                    sx={{ width: 30, height: 30 }}
                  />
                </StyledBadge>
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    width: "100%",
                    lineHeight: 0.8,
                  }}
                >
                  <Typography
                    color="inherit"
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    {topDisplay?.user_data?.full_name || ""}
                  </Typography>
                  <Typography color="neutral.400" sx={{ fontSize: 13 }}>
                    {topDisplay?.role
                      ? peopleRoleRelationshipMap[topDisplay?.role]
                      : ""}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <SvgIcon sx={{ fontSize: 16 }}>
            <ChevronSelectorVerticalIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        tenants={displayList}
        loadingViewingUserData={
          loadingViewingUserData || targetAuthRolesLoading
        }
        userData={userData}
        ownerUserData={ownerUserData}
        proClientContext={proClientContext}
        onClose={popover.handleClose}
        handleSelect={handleSelect}
        onAddPlanClick={props.onAddPlanClick}
        onVerifyUserClick={props.onVerifyUserClick}
        handleSetAuthorizationContext={handleSetAuthorizationContext}
        handleSetClientContext={handleSetClientContext}
      />
    </>
  );
};

TenantSwitch.propTypes = {
  // @ts-ignore
  sx: PropTypes.object,
};
